export const getMode = connection => {
  return connection.rpcEndpoint.indexOf('devnet') > -1 ? 'devnet' : 'mainnet';
};

export const getFormattedTime = (txnCount, txnRate) => {
  if (!(txnRate > 0)) {
    return '';
  }

  const diffInMilliseconds = Math.floor((txnCount * 60 * 1000) / txnRate);

  const diffInSeconds = Math.floor(diffInMilliseconds / 1000); // Total seconds
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60)); // Total minutes
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60)); // Total hours
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)); // Total days

  // Calculate the remaining hours and minutes after extracting days and hours
  const remainingHours = diffInHours % 24;
  const remainingMinutes = (diffInMinutes % 60) + (diffInSeconds % 60 ? 1 : 0);

  const pluralize = (value, unit) =>
    `${value} ${unit}${value === 1 ? '' : 's'}`;

  if (diffInDays > 0) {
    return `${pluralize(diffInDays, 'day')}, ${pluralize(remainingHours, 'hour')}`;
  } else if (diffInHours > 0) {
    return `${pluralize(diffInHours, 'hour')}, ${pluralize(remainingMinutes, 'minute')}`;
  } else {
    return `${pluralize(remainingMinutes, 'minute')}`;
  }
};
export const getEstimatedVolume = (txnCount, amount, price) => {
  return `${(txnCount * amount * price * 2).toFixed(2)} $`;
};

export const BOT_MIN_RATE = 50;
export const BOT_MAX_RATE = 200;
export const BOT_DEFAULT_RATE = 100;

export const BOT_MIN_COUNT = 100;
export const BOT_DEFAULT_COUNT = 1000;

// bumpi bot configurations
export const BUMPI_MIN_RATE = 50;
export const BUMPI_MAX_RATE = 200;
export const BUMPI_DEFAULT_RATE = 100;
export const BUMPI_MIN_COUNT = 20;
export const BUMPI_DEFAULT_COUNT = 1000;

// volume bot configuration
export const VOLUME_MIN_RATE = 10;
export const VOLUME_MAX_RATE = 200;
export const VOLUME_DEFAULT_RATE = 10;
export const VOLUME_MIN_COUNT = 20;
export const VOLUME_DEFAULT_COUNT = 500;
export const VOLUME_AMOUNT_TABS = [
  {
    label: '0.15',
    value: '0.15',
  },
  {
    label: '0.25',
    value: '0.25',
  },
  {
    label: '0.5',
    value: '0.5',
  },
];

// trending bot configuration

//
